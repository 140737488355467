import axios from "axios";
const domain = "https://api.hakulhi.com/"; //"https://api.eventsmv.online/"; //"http://localhost:8002/"; //"https://api-demo.hms.asia/";
const baseDomain = domain + "api/public/"; //"http://localhost:8000/api/admin/";
const exhibitorDomain = domain + "api/exhibitors/";

const tok = localStorage.getItem("er_public_token");

export const customHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${tok}`,
};

export const baseUrl = `${baseDomain}`;
export const exhibitorUrl = `${exhibitorDomain}`;
export const imageUrl = domain + `storage/`;

export default axios.create({
  baseUrl,
  headers: customHeaders,
  imageUrl,
});

export const serializeQuery = (query) => {
  return Object.keys(query)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    )
    .join("&");
};
