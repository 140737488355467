import Vue from "vue";
import VueRouter from "vue-router";
//import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../components/public/index.vue"),
  },
  {
    path: "/rsvp/:id/:slug",
    name: "EventRegistration",
    component: () =>
      import(/* webpackChunkName: "public" */ "../components/rsvp/index"),
  },
  {
    path: "/in/:id",
    name: "EventPresent",
    component: () =>
      import(/* webpackChunkName: "in" */ "../components/public/present"),
  },
  {
    path: "/rsvpc/:id/:slug",
    name: "EventRegistration",
    component: () =>
      import(/* webpackChunkName: "public" */ "../components/rsvp/verify"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () =>
      import(
        /* webpackChunkName: "dashboard" */ "../components/dashboard/index.vue"
      ),
  },
  {
    path: "/register-event/:id",
    name: "RegisterEvent",
    component: () =>
      import(
        /* webpackChunkName: "register-event" */ "../components/exhibitor/addEvent.vue"
      ),
  },
  {
    path: "/exhibitor/:id/:slug",
    name: "RegisterExhibitor",
    component: () =>
      import(
        /* webpackChunkName: "exhibitor" */ "../components/exhibitor/index.vue"
      ),
  },
  {
    path: "/email/comp/:id/:slug/:param",
    name: "ActivateCompanyEmail",
    component: () =>
      import(
        /* webpackChunkName: "activate-company-email" */ "../components/exhibitor/activate.vue"
      ),
  },
  {
    path: "/email/cont/:id/:slug/:param",
    name: "ActivateContactEmail",
    component: () =>
      import(
        /* webpackChunkName: "activate-contact-email" */ "../components/exhibitor/contactActivate.vue"
      ),
  },
  {
    path: "/email/cont/:id/:slug",
    name: "RegisterExhibitorContact",
    component: () =>
      import(
        /* webpackChunkName: "exhibitor" */ "../components/exhibitor/index.vue"
      ),
  },
  {
    path: "/exhibitor/event/view/:id",
    name: "ViewExhibitorEvent",
    component: () =>
      import(
        /* webpackChunkName: "exhibitor" */ "../components/exhibitor/eventProfie.vue"
      ),
  },
  {
    path: "/exhibitor",
    name: "ViewExhibitor",
    component: () =>
      import(
        /* webpackChunkName: "exhibitor" */ "../components/exhibitor/my.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../components/auth/forgotPassword.vue"
      ),
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../components/auth/SignIn"),
  },
  {
    path: "/sign-out",
    name: "SignOut",
    component: () =>
      import(/* webpackChunkName: "sign-in" */ "../components/auth/SignOut"),
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "change-password" */ "../components/auth/changepassword"
      ),
  },
  {
    path: "/visitor-log",
    name: "VisitorLog",
    component: () =>
      import(
        /* webpackChunkName: "visitor-log" */ "../components/exhibitor/qrscan.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
